import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validTwitterHandle = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value) {
    return null;
  }

  const twitterRegex = new RegExp(/^@[a-z0-9_]{1,15}$/i);

  return !twitterRegex.test(value) ? { twitterHandle: true } : null;
};

export const markControlsDirty = (group: UntypedFormGroup | UntypedFormArray): void => {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl: any = (group as UntypedFormGroup).controls[key];

    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      markControlsDirty(abstractControl);
    } else {
      abstractControl.markAsDirty();
      abstractControl.markAllAsTouched();
      abstractControl.updateValueAndValidity();
    }
  });
};

export const validateEmailTargets = (control: AbstractControl) => {
  if (control && control.parent) {
    const emailTargets = (control.parent as FormGroup).get('emailTargets');
    const targets = (control.parent as FormGroup).get('targets') as FormArray;

    if (emailTargets) {
      if ((control.touched || control.dirty) && (!control.value || control.value.trim() === '')) {
        return { required: true };
      }

      if ((targets.controls as FormGroup[]).some((t) => t.value.name !== '' && t.value.emails.length === 0)) {
        control.markAsDirty();
        return { emailTargetsScript: 'One or more of your targets does not have an email address' };
      }

      return null;
    }

    return null;
  }

  return null;
};

export const website = (control: AbstractControl): { [key: string]: boolean } | null => {
  // Regex to check for http/https protocol and valid domain name and query parameters
  // eslint-disable-next-line @typescript-eslint/quotes
  const urlRegex = new RegExp("^(http|https)://[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)+(:[0-9]+)?(\\/[a-zA-Z0-9-._~:\\/?#[\\]@!$&'()*+,;=%]*)?$");

  return !control.value || urlRegex.test(control.value) ? null : { website: true };
};

export const passwordMatch: ValidatorFn = (form: AbstractControl): ValidationErrors | null => {
  const password = form.get('password');
  const confirmPassword = form.get('confirmPassword');

  if (!password || !confirmPassword) {
    return null;
  }

  if (password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ custom: 'Passwords do not match' });
  }

  return null;
};
